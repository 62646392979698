import React, { useState } from "react";
import { Button } from "../ui";
import styles from "./help.module.css";
import ArrowBackIcon from "../../../assets/icons/arrowBack.svg";
import { tr } from "../../../helpers/languages";
import HelpDashIcon from "./HelpDashIcon";
import { LogEvent } from "../../analytics/eventTracker";

type HelpPageProps = {
    goContents: () => void
};

const ReturnToContents = (props: { goToContents: () => void }) => {
    return (
        <div className={styles.returnToContents}>
            <div className="settingsIconContainer" onClick={props.goToContents}>
                <img src={ArrowBackIcon} className="settingsIcon" alt="" aria-label={tr("HELP_RETURN_TO_CONTENTS")}></img>
            </div>
        </div>
    );
};
// const PageTemplate = (props: HelpPageProps) => {
//     return (
//         <div className={styles.container}>
//             <ReturnToContents goToContents={props.goContents} />
//             <h1 className={styles.header}>Example</h1>
//         </div>
//     );
// }
const ReportCards = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>Report Cards</h1>
            <p>
                The main dashboard utilises what we call Report Cards, these are self-contained elements which are added to the dashboard and displays
                specific information about your organisation. Each report card will contain 4 main sections
            </p>
            <ol>
                <li>A Card Title</li>
                <li>The Cards Content</li>
                <li>The Cards Details</li>
                <li>The Cards Help Button</li>
            </ol>
            <div
                className="dash-card"
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "20px"
                }}
            >
                <header className="dash-header draggable-ind">
                    <h2>Card Title</h2>
                    <HelpDashIcon openHelpPopup={() => console.log("Help Popup")} />
                </header>
                <div
                    style={{
                        display: "flex",
                        placeContent: "center",
                        height: "164px"
                    }}
                >
                    <h3
                        style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontSize: "32px",
                            userSelect: "none"
                        }}
                    >
                        Card Content
                    </h3>
                </div>
            </div>
            <p>Card Title: This is the informational title of the card telling you what information the card is displaying.</p>
            <p>Card Content: This is overview content of the card showing you base stats of the detailed information.</p>
            <p>
                Card Details: When you click on the Card title, a new window will load providing you the detailed information the card content showed
                you.
            </p>
            <p>Card Help Button: When you click on the help button the relevant help page is shown.</p>
        </div>
    );
};
const ServerStatusCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERSTATUS_TITLE")}</h1>
            <p>
                This card tells you the current online status of the servers that has been installed into the CSE Remote Agent system. It breaks down
                the detail to Total servers which the reporting knows about, how many of those servers are reading as online and how many are reading
                as offline.
            </p>
            <p>
                The detailed information page lists the servers in order of Offline first, we can see on this page the name of the servers, and what
                time they were seen as offline.
            </p>
            <h4>What to do if servers are Offline?</h4>
            <p>
                The Reporting system will should have created a service request that the Server is offline and that should have notified the relevant
                technical staff to take a look at it.
            </p>
            <p>However, servers could be offline for a number of reasons. Including:</p>
            <ul>
                <li>The server has been turned off or has failed.</li>
                <li>The server is not connected to the internet.</li>
                <li>The server has been decommissioned but not removed from the Reporting system.</li>
                <li>The CSE Remote Agent isn&apos;t running.</li>
            </ul>
            <p>
                Regardless of the problem, the first thing to do is to try and access the server and assess the situation. When the CSE Remote Agent
                can see the server back online it will automatically close the service request it generated.
            </p>
        </div>
    );
};
const ServerThreatCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_AV_SERVER_TITLE")}</h1>
            <p>
                The anti-threat status card tells you the current status of the anti-threat/anti-virus software installed on the servers and is broken
                it down to 4 categories:
            </p>
            <ul>
                <li>
                    Up-to-date: This indicates that the software is working fine and running on the server. We don&apos;t display this number but is
                    visible by the green section of the pie chart.
                </li>
                <li>Out-of-date: The software is running but its definition files for thread detection could do with an update.</li>
                <li>Not Running: The software is not actively running so will not be protecting this device.</li>
                <li>No Anti-Threat: We can&apos;t find any anti-threat software installed on the server. This device is not being protected.</li>
            </ul>
            <p>
                Auto Resolution: The CSE Remote agent will try to correct servers which are out-of-date by requesting the installed software to run an
                update, if this is successful the requests raised by this will be closed.
            </p>
            <p>The details page shows the list of servers by name, the anti-threat product detected and the current status.</p>
            <h4>What to do if my servers aren&apos;t up-to-date?</h4>
            <p>
                If your servers aren&apos;t showing as up-to-date then the CSE Remote Agent will automatically create a service request informing the
                relevant technical staff to take a look at the server.
            </p>
            <p>There are a number of reasons why the servers might be out-of-date, Not Running or No Anti-threat</p>
            <ul>
                <li>
                    The server might require a reboot to complete an update or installation. This can cause the system to actually not detect the
                    threat protection as it is in a half-installed state.
                </li>
                <li>The anti-threat software has an issue and needs repairing.</li>
            </ul>
            <p>
                After the server has been looked at by a technician and the problem is resolved the request will automatically close itself once the
                server is detected as Up-to-date.
            </p>
        </div>
    );
};
const ServerVolStorageCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_VOL_STOR_SERVER_TITLE")}</h1>
            <p>
                This card showed the number of know server disk volumes that have been found on servers by the CSE Remote Agent. It then works out how
                full these volumes are and if they are over 90% full.{" "}
            </p>
            <p>
                The report breaks down these volumes in the card details page, listing all the volumes it knows about. This tells us the name of the
                Server the volume is found on, which drive letter it has assigned to it and its total size, used size, free space and lastly the
                percentage used.
            </p>
            <h4>What to do if server volumes are over 90%?</h4>
            <p>
                If a volume is 90% used or over the reporting system will automatically create a service request for this volume and that should have
                notified the relevant technical staff to take a look at it.{" "}
            </p>
            <p>
                Server volumes can get full for a lot of different reasons which is why it typically takes a technician to take a look at the reason
                why this has occurred. Here are some examples:
            </p>
            <ul>
                <li>An installed application on the server could be generating a large number of log files.</li>
                <li>Student or staff home directories are growing over time from everyday use. </li>
                <li>A Database server has grown over time from everyday use.</li>
            </ul>
            <p>Whatever the reason for a volume getting to 90% there are really only two solutions.</p>
            <ol>
                <li>Clear the space by removing it.</li>
                <li>Increasing the total size of the volume.</li>
            </ol>
            <p>Once the volume is below 90% the reporting system will automatically close the service request.</p>
        </div>
    );
};
const ServerLoginCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_LOGIN_SERVER_TITLE")}</h1>
            <p>
                This is an informational card telling you the number of users that have logged directly in to the known servers. It provides you the
                number of logins that have been detected on servers within the report time period and the top 3 servers based on the number of logins
                in that report time period.
            </p>
            <p>
                The card details lists all the known users which have logged into the servers and included the server name, the username, evert type
                and the date/time of the login.
            </p>
            <h4>What do I do with this card?</h4>
            <p>
                This card is for informational purposes only, it doesn&apos;t indicate any problems but provides you a quick way of see who has been
                logging into the servers quickly and easily.
            </p>
            <p>
                However, if you see a username that shouldn&apos;t be logging into a server it is recommended you point this out to the relevant
                technical staff.
            </p>
        </div>
    );
};
const ServerWinUpdatesCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_WIN_UPDATE_TITLE")}</h1>
            <p>
                This card tells us the number of servers that have pending windows updates and how many are currently up-to-date and gives you an
                overview of your current windows update status on your servers overall.
            </p>
            <p>The card detail page provides a list of servers and the number of updates that are pending to be installed on the server.</p>
            <h4>What to do if servers are pending updates?</h4>
            <p>
                This can be very much a moving goal post as windows updates can be released every week by Microsoft and if servers are updated on a
                Friday, then by Wednesday they could be showing as pending again.
            </p>
            <p>
                Typically, it can be more useful to look the details page of this card and see if there are servers that have a large number of
                updates pending (10+), this would indicate that the server hasn&apos;t been updated in a while. At this point it is suggested the
                server should be updated which would require downtime or out-of-hours work to be done.
            </p>
        </div>
    );
};
const BackupCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_BACKUPS_TITLE")}</h1>
            <p>
                The backup card shows the number of backups that the CSE Remote Agent has detected on servers and provides the total number of backups
                within the Report time period. The reporting system breaks them down to:
            </p>
            <ul>
                <li>Successful: Backups have been successful.</li>
                <li>Successful with Warnings: Backups have been successful but have some warnings.</li>
                <li>Failed: Backup has failed.</li>
            </ul>
            <p>
                Clicking on the card details provides a list of all the know backups in the report time period and provides which backup product it
                was originated from, the backup result, the server it originated from, the message from the backup and the date.
            </p>
            <h4>What to do if there are failed backups?</h4>
            <p>
                The reporting system upon detecting a failed backup will create a service request on this event that notifies the relevant technical
                staff of the issue. At this point the technical staff need to review and look into why the backup failed to prevent repeated failures.
            </p>
        </div>
    );
};
const UnexpectedShutdownCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_UNEXP_SHUTDOWN_TITLE")}</h1>
            <p>
                The unexpected shutdown card provides information on if a server has shutdown unexpectedly and ungracefully within the report time
                period. This can happen from crashes, force shutdowns or power being disconnected while the server is running.
            </p>
            <p>The card detail shows a list of servers, dates and message detected on each incident of an unexpected shutdown.</p>
            <h4>What to do if a server has shutdown unexpectedly?</h4>
            <p>
                When the CSE reporting system detects an unexpected shutdown it will create a service request on this event that notifies the relevant
                technical staff of the issue. At this point the technical staff need to review and look into why the server shutdown unexpectedly.
            </p>
        </div>
    );
};
const CSERemoteAgentCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_CSE_REMAGENT_TITLE")}</h1>
            <p>
                This card is providing information about all the requests generated by the CSE Remote Agent. Which includes Anti-threat, Backup,
                Unexpected Shutdowns, Server Volume space, etc. This card brings them all together to see if the requests generated by the system have
                been resolved or not and lists them as.
            </p>
            <ul>
                <li>Total</li>
                <li>Resolved</li>
                <li>Unresolved</li>
            </ul>
            <p>
                The card details provide more information including which request have been resolved and which are currently unresolved. Information
                includes:
            </p>
            <ul>
                <li>Service Request ID</li>
                <li>Device</li>
                <li>Category</li>
                <li>Message</li>
                <li>Date</li>
            </ul>
            <h4>What do I do with this card?</h4>
            <p>
                This is an informational card informing you off all the request generated by the CSE Remote Agent. It can be useful to look back
                through the resolved alerts to see if there are any trends on servers, for example memory going over 90% regularly.
            </p>
        </div>
    );
};
const NetworkDeviceStatusCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_NETWORK_DEVICE_STATUS_TITLE")}</h1>
            <p>This card tells you about the current online status of the network devices that are being monitored by CSE Remote Agent.</p>
            <p>The card details page list the devices that are currently online and offline.</p>
            <h4>What to do if a device is marked as offline?</h4>
            <p>
                When a device is marked as offline it is likely that the device is unable to connect to the internet or is disconnected. The relevant
                technical staff will be required to investigate why this device is offline.
            </p>
        </div>
    );
};
const NetworkSwitchDeviceStatusCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TITLE")}</h1>
            <p>This card tells you about the current online status of the network switches that are being monitored by CSE Remote Agent.</p>
            <p>The card details page list the switches that are currently online and offline.</p>
            <h4>What to do if a device is marked as offline?</h4>
            <p>When a switch is marked as offline the relevant technical staff will be required to investigate why this device is offline.</p>
        </div>
    );
};
const NetworkWAPDeviceStatusCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_NETWORK_DEVICE_STATUS_WAP_TITLE")}</h1>
            <p>This card tells you about the current online status of the wireless access points that are being monitored by CSE Remote Agent.</p>
            <p>The card details page list the access points that are currently online and offline.</p>
            <h4>What to do if a device is marked as offline?</h4>
            <p>
                When a wireless access point is marked as offline the relevant technical staff will be required to investigate why this device is
                offline.
            </p>
        </div>
    );
};
const AppUsageCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_APP_USAGE_TITLE")}</h1>
            <p>
                Applications can be setup to be recorded by the CSE reporting system and the CSE Remote agent will record if the application has been
                opened for more that 1 minute. With this information this card shows the top 3 applications used the most over the report time period.
            </p>
            <p>
                Card details displays a list of all known applications, the process that is being recorded, the publisher, the number of times used
                and on the number of devices used.
            </p>
            <h4>What do I do with this card?</h4>
            <p>
                This card is for informational purposes only to help provide insight into which applications are being used at the school. If an
                application isn&apos;t in this list you can request it be added to be recorded by contacting the CSE support team on 01993 886688
                option 1.
            </p>
        </div>
    );
};
const StationLastBootedCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_STATIONLASTSEEN_TITLE")}</h1>
            <p>
                This card tells you how many desktop and laptop computers have been turned on within the 4 date ranges 90, 30, 14 and 7 days. It also
                tells you the total stations the reporting system is aware of.
            </p>
            <p>
                The card detail page shows a list of all the known desktop and laptop computers and tries to display some information about the
                stations including:
            </p>
            <ul>
                <li>Name</li>
                <li>Manufacturer</li>
                <li>Model</li>
                <li>Serial Number</li>
                <li>CSE Remote Agent Client Version</li>
                <li>Processor</li>
                <li>Memory</li>
                <li>Last Logged in User</li>
            </ul>
            <h4>What do I do with this card?</h4>
            <p>This card is for informational purposes only to help provide insight into how many stations are being used at the school.</p>
        </div>
    );
};
const StationThreatCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_AV_STATION_TITLE")}</h1>
            <p>
                The Threat protection card tells you the current status of the anti-threat/anti-virus software installed on desktop and laptop
                computers. It is broken it down to 4 categories:
            </p>
            <ul>
                <li>
                    Up-to-date: This indicates that the software is working fine and running on the computer. We don&apos;t display this number but is
                    indicated by the percentage gauge on how many computers are protected.
                </li>
                <li>Out-of-date: The software is running but its definition files for thread detection could do with an update.</li>
                <li>Not Running: The software is not actively running so will not be protecting this device.</li>
                <li>No Anti-Threat: We can&apos;t find any anti-threat software installed on the server. This device is not being protected.</li>
            </ul>
            <p>
                Auto Resolution: The CSE Remote agent will try to correct computers which are out-of-date by requesting the installed software to run
                an update, the system will also attempt to run the software&apos;s installer if it hasn&apos;t detected that an anti-threat product
                isn&apos;t installed.
            </p>
            <p>The details page shows the list of computers by name, the anti-threat product detected and the current status.</p>
            <h4>What to do if my station isn&apos;t up-to-date?</h4>
            <p>There are a number of reasons why the computers might be out-of-date, Not Running or No Anti-threat:</p>
            <ul>
                <li>
                    The computer might require a reboot to complete an update or installation. This can cause the system to actually not detect the
                    threat protection as it is in a half-installed state.
                </li>
                <li>The anti-threat software has an issue and needs repairing.</li>
            </ul>
            <p>
                Typically, from the large number of computers and the nature of requiring reboots to update or computers being slow to update this
                figure is never 100%, instead we should be looking to try and get the percentage to around the 90% mark.
            </p>
        </div>
    );
};
const StationVolStorageCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_VOL_STOR_STATION_TITLE")}</h1>
            <p>
                This card showed the number of know computer disk volumes that have been found on desktop and laptop computers by the CSE Remote
                Agent. It then works out how full these volumes are and if they are over 90% full.{" "}
            </p>
            <p>
                The report breaks down these volumes in the card details page, listing all the volumes it knows about. This tells us the name of the
                computer the volume is found on, which drive letter it has assigned to it and its total size, used size, free space and lastly the
                percentage used.
            </p>
            <p>
                Auto Resolution: The CSE remote agent when it detects a computer&apos;s volume is over 90% it will run a disk clean up to try and
                resolve this issue.
            </p>
            <h4>What to do if computer volumes are over 90%?</h4>
            <p>
                If computer volumes continue to be an issue with running out of space and disk clean ups aren&apos;t resolving the issues then it will
                take a technician to have a look at the computers in question and work out what the cause of this is.
            </p>
        </div>
    );
};
const StationLoginCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_LOGIN_STATION_TITLE")}</h1>
            <p>
                This is an informational card telling you the number of users that have logged directly in to the known computers. It provides you the
                number of logins that have been detected on desktop and laptop computers within the report time period and the top 3 computers based
                on the number of logins in that report time period.
            </p>
            <p>
                The card details lists all the known users which have logged into the computers and included the computer name, the username, evert
                type and the date/time of the login.
            </p>
            <h4>What do I do with this card?</h4>
            <p>This card is for informational purposes only, it doesn't indicate any problems.</p>
        </div>
    );
};
const LaptopBatteryHealthCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE")}</h1>
            <p>
                This card shows the number of known notebooks and the health of their batteries. It then shows the batteries that report less than 85%
                of their total capacity as poor health.
            </p>
            <p>
                The card details page displays a list of all notebooks that have been scanned and their battery health as a percentage that indicates
                the laptops battery's maximum capacity.
            </p>
            <h4>What to do if a notebooks battery is reported as poor health?</h4>
            <p>When a notebook's battery is being reported as poor health it's likely that the battery needs to be replaced soon. </p>
        </div>
    );
};
const StationWindowsElevenReadinessCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_WINDOWS_ELEVEN_READY_TITLE")}</h1>
            <p>This card tells you the number of devices that are ready to perform the Windows 11 upgrade.</p>
            <p>The card details page lists all the devices that have been scanned and allows filtering by device host name.</p>
            <h4>What to do if a device isn't ready for the Window 11 Upgrade?</h4>
            <p>
                If a device isn't ready for the Windows 11 Upgrade, then it will require a technician to have a look at the device in question before
                the upgrade can be performed.
            </p>
        </div>
    );
};
const ServiceDeskCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_TITLE")}</h1>
            <p>This card provides information regarding the CSE Service Desk request stats. It includes the following information:</p>
            <ul>
                <li>Current Open Requests: This is the current number of open requests in the service desk at this moment in time.</li>
                <li>Opened last x Days: This is the number of requests that have been created in the report time period.</li>
                <li>Closed last x Days: This is the number of requests that have been closed in the report time period.</li>
                <li>Reopened last x days: This is the number of requests that have been closed and then reopened in the report time period.</li>
                <li>SLA: This is the current SLA percentage that has been achieved in the report time period.</li>
            </ul>
            <p>
                The card details page shows a break-down of number of requests per site as well as a user activity report within the report time
                period, ordered in the highest service desk user first.
            </p>
            <h4>What do I do with this card?</h4>
            <p>
                This is another informational card providing you information about the current stats regarding the CSE Service Desk, so you can keep
                an eye on total requests in the service desk and how technicians are performing.
            </p>
        </div>
    );
};
const ServiceDeskTrendsCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_TRENDS_TITLE")}</h1>
            <p>This card shows the number of open service desk requests over the provided time frame.</p>
            <p>The card details page provides a larger graph and buttons to view the last year of open service desk requests.</p>
            <h4>What do I do with this card?</h4>
            <p>
                This is an informational card providing you information about the current stats regarding the open service desk requests over the
                provided time frame.
            </p>
        </div>
    );
};
const ServiceDeskOpenedCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_OPEN_REQ_TITLE")}</h1>
            <p>This card shows the number of created service desk requests over the provided time frame.</p>
            {/* <p>The card details page provides a larger graph and buttons to view the last year of open service desk requests.</p> */}
            <h4>What do I do with this card?</h4>
            <p>
                This is an informational card providing you information about the current stats regarding the created service desk requests over the
                provided time frame.
            </p>
        </div>
    );
};
const ServiceDeskClosedCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE")}</h1>
            <p>This card shows the number of closed service desk requests over the provided time frame.</p>
            {/* <p>The card details page provides a larger graph and buttons to view the last year of closed service desk requests.</p> */}
            <h4>What do I do with this card?</h4>
            <p>
                This is an informational card providing you information about the current stats regarding the closed service desk requests over the
                provided time frame.
            </p>
        </div>
    );
};
const ServerRestartCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVER_RESTART_TITLE")}</h1>
            <p>This card shows the servers that currently require a restart. </p>
            <p>To see which servers, require a restart click on the title to view the card details page.</p>
            <h4>What to do if a server requires a restart?</h4>
            <p>If a server is pending an update a restart is required at the next available down time.</p>
        </div>
    );
};
const ServiceDeskSitePerformanceCard = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_PERF_TITLE")}</h1>
            <p>This card tells you the SLA performance for each site over the provided time frame.</p>
            <p>The card is only displayed if you have multiple sites.</p>
            <p>
                The card details page shows each site and their service desk performance. Buttons are added to the top left of the page to easily view
                data for the current academic year and each term. If the term selected has not started, data from the previous year will be returned.
                The time frame is displayed below the heading.
            </p>
            <h4>What do I do with this card?</h4>
            <p>This is an informational card providing you information about each site's performance.</p>
        </div>
    );
};
const ActivityPerUser = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_USER_ACTIVITY_TITLE")}</h1>
            <p>This card tells you about the CSE Service Manager activity per user.</p>
            <p>The card details page breaks down each user's CSE Service Manager activity.</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you information about each user's performance.</p>
        </div>
    );
};
const ServiceDeskFeedback = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")}</h1>
            <p>This card tells you about feedback left on closed Service Requests.</p>
            <p>Feedback Left is a percentage of feedback left compared to the total number of requests closed in the given time frame.</p>
            <p>The card details page breaks down each feedback left on closed Service Requests.</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you the feedback left on closed Service Requests in the provided time frame.</p>
        </div>
    );
};
const ServiceDeskTopKeywords = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_KEYWORDS_TITLE")}</h1>
            <p>This card tells you about the top keywords provided by Sami AI.</p>
            <p>The card details page breaks down each feedback left on closed Service Requests.</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you the top keywords from service requests created within the given timeframe. It is possible that this card could display trends in service requests, for example an increase in virus or malware related requests.</p>
            <p>Users of Sami's services acknowledge that the creators and operators of Sami shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the information provided by the service assistant. This includes, but is not limited to, any errors or omissions in the content, or any actions taken or not taken based on the advice given by Sami.</p>
            <p>The data displayed in this card is subject to Sami's disclaimer. For more information please read the disclaimer for Sami. <a href="https://cseservicemanager.co.uk/sami/disclaimer" style={{ textDecoration: "underline" }}>https://cseservicemanager.co.uk/sami/disclaimer</a></p>
        </div>
    );
};
const ServiceDeskTopCategories = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SERVICEDESK_CATEGORIES_TITLE")}</h1>
            <p>This card tells you about the top categories provided by Sami AI.</p>
            <p>The card details page breaks down each feedback left on closed Service Requests.</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you the top categories from service requests created within the given timeframe. It is possible that this card could display trends in service requests, for example an increase in virus or malware related requests.</p>
            <p>Users of Sami's services acknowledge that the creators and operators of Sami shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the information provided by the service assistant. This includes, but is not limited to, any errors or omissions in the content, or any actions taken or not taken based on the advice given by Sami.</p>
            <p>The data displayed in this card is subject to Sami's disclaimer. For more information please read the disclaimer for Sami. <a href="https://cseservicemanager.co.uk/sami/disclaimer" style={{ textDecoration: "underline" }}>https://cseservicemanager.co.uk/sami/disclaimer</a></p>
        </div>
    );
};
const SamiTokenUsage = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SAMI_TOKEN_USAGE_TITLE")}</h1>
            <p>This card tells you about the token usage per each user of Sami.</p>
            <p>Tokens can be thought of as pieces of words. Before Sami processes the request, the users input is broken down into tokens. These tokens are not cut up exactly where the words start or end - tokens can include trailing spaces and even sub-words. Here are some helpful rules of thumb for understanding tokens in terms of lengths:</p>
            <ul>
                <li>1 token ~= 4 chars in English</li>
                <li>1 token ~= ¾ words</li>
                <li>100 tokens ~= 75 words</li>
            </ul>
            <p>Or</p>
            <ul>
                <li>1-2 sentence ~= 30 tokens</li>
                <li>1 paragraph ~= 100 tokens</li>
                <li>1,500 words ~= 2048 tokens</li>
            </ul>
            <p>The card details page breaks down each users total token usage.</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you the users that are using the most tokens when interacting with Sami. This can be used with the Sami Conversation Count card to find the most active users of Sami.</p>
            <p>Users of Sami's services acknowledge that the creators and operators of Sami shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the information provided by the service assistant. This includes, but is not limited to, any errors or omissions in the content, or any actions taken or not taken based on the advice given by Sami.</p>
            <p>The data displayed in this card is subject to Sami's disclaimer. For more information please read the disclaimer for Sami. <a href="https://cseservicemanager.co.uk/sami/disclaimer" style={{ textDecoration: "underline" }}>https://cseservicemanager.co.uk/sami/disclaimer</a></p>
        </div>
    )
}
const SamiConversationCount = (props: HelpPageProps) => {
    return (
        <div className={styles.container}>
            <ReturnToContents goToContents={props.goContents} />
            <h1 className={styles.header}>{tr("DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE")}</h1>
            <p>This card tells you about the users that have had the most conversations with Sami within the provided time frame.</p>
            <p>The card details page breaks down each users total conversation count within the given timeframe</p>
            <h4>What to do with this card?</h4>
            <p>This is an informational card providing you the users that have had the most conversations with Sami. This can be used with the Sami Token Usage card to find the most active users of Sami.</p>
            <p>Users of Sami's services acknowledge that the creators and operators of Sami shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use the information provided by the service assistant. This includes, but is not limited to, any errors or omissions in the content, or any actions taken or not taken based on the advice given by Sami.</p>
            <p>The data displayed in this card is subject to Sami's disclaimer. For more information please read the disclaimer for Sami. <a href="https://cseservicemanager.co.uk/sami/disclaimer" style={{ textDecoration: "underline" }}>https://cseservicemanager.co.uk/sami/disclaimer</a></p>
        </div>
    )
}

const Contents = (props: { changePage: (arg0: string) => any }) => {
    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Help</h1>
            <div className={styles.contentsContainer}>
                <Button className={styles.button} primary onClick={() => props.changePage("ReportCards")}>
                    Report Cards
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerStatusCard")}>
                    {tr("DASHBOARD_SERSTATUS_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerThreatCard")}>
                    {tr("DASHBOARD_AV_SERVER_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerVolStorageCard")}>
                    {tr("DASHBOARD_VOL_STOR_SERVER_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerLoginCard")}>
                    {tr("DASHBOARD_LOGIN_SERVER_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerWinUpdatesCard")}>
                    {tr("DASHBOARD_WIN_UPDATE_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("BackupCard")}>
                    {tr("DASHBOARD_BACKUPS_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("UnexpectedShutdownCard")}>
                    {tr("DASHBOARD_UNEXP_SHUTDOWN_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("CSERemoteAgentCard")}>
                    {tr("DASHBOARD_CSE_REMAGENT_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServerRestartCard")}>
                    {tr("DASHBOARD_SERVER_RESTART_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("NetworkDeviceStatusCard")}>
                    {tr("DASHBOARD_NETWORK_DEVICE_STATUS_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("NetworkDeviceSwitchStatusCard")}>
                    {tr("DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("NetworkDeviceWAPStatusCard")}>
                    {tr("DASHBOARD_NETWORK_DEVICE_STATUS_WAP_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("AppUsageCard")}>
                    {tr("DASHBOARD_APP_USAGE_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("StationLastBootedCard")}>
                    {tr("DASHBOARD_STATIONLASTSEEN_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("StationThreatCard")}>
                    {tr("DASHBOARD_AV_STATION_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("StationVolStorageCard")}>
                    {tr("DASHBOARD_VOL_STOR_STATION_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("LaptopBatteryHealthCard")}>
                    {tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("StationWindowsElevenReadinessCard")}>
                    {tr("DASHBOARD_WINDOWS_ELEVEN_READY_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("StationLoginCard")}>
                    {tr("DASHBOARD_LOGIN_STATION_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskCard")}>
                    {tr("DASHBOARD_SERVICEDESK_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskTrendsCard")}>
                    {tr("DASHBOARD_SERVICEDESK_TRENDS_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskCreatedRequestsCard")}>
                    {tr("DASHBOARD_SERVICEDESK_OPEN_REQ_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskClosedRequestsCard")}>
                    {tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskSitePerformanceCard")}>
                    {tr("DASHBOARD_SERVICEDESK_PERF_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ActivityPerUserCard")}>
                    {tr("DASHBOARD_USER_ACTIVITY_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskFeedback")}>
                    {tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskTopKeywords")}>
                    {tr("DASHBOARD_SERVICEDESK_KEYWORDS_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("ServiceDeskTopCategories")}>
                    {tr("DASHBOARD_SERVICEDESK_CATEGORIES_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("SamiTokenUsage")}>
                    {tr("DASHBOARD_SAMI_TOKEN_USAGE_TITLE")} Card
                </Button>
                <Button className={styles.button} primary onClick={() => props.changePage("SamiConversationCount")}>
                    {tr("DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE")} Card
                </Button>
            </div>
        </div>
    );
};

const Help = (props: { helpSection: any }) => {
    if (props.helpSection !== "") {
        LogEvent(5, { page: props.helpSection });
    }
    const [section, setSection] = useState(props.helpSection || "");
    const NavigateTo = (page: string) => {
        setSection(page);
    };
    const goToContents = () => {
        setSection("Contents");
    };
    switch (section) {
        case "ReportCards":
            return <ReportCards goContents={goToContents} />;
        case "ServerStatusCard":
            return <ServerStatusCard goContents={goToContents} />;
        case "ServerThreatCard":
            return <ServerThreatCard goContents={goToContents} />;
        case "ServerVolStorageCard":
            return <ServerVolStorageCard goContents={goToContents} />;
        case "ServerLoginCard":
            return <ServerLoginCard goContents={goToContents} />;
        case "ServerWinUpdatesCard":
            return <ServerWinUpdatesCard goContents={goToContents} />;
        case "BackupCard":
            return <BackupCard goContents={goToContents} />;
        case "UnexpectedShutdownCard":
            return <UnexpectedShutdownCard goContents={goToContents} />;
        case "CSERemoteAgentCard":
            return <CSERemoteAgentCard goContents={goToContents} />;
        case "NetworkDeviceStatusCard":
            return <NetworkDeviceStatusCard goContents={goToContents} />;
        case "NetworkDeviceSwitchStatusCard":
            return <NetworkSwitchDeviceStatusCard goContents={goToContents} />;
        case "NetworkDeviceWAPStatusCard":
            return <NetworkWAPDeviceStatusCard goContents={goToContents} />;
        case "AppUsageCard":
            return <AppUsageCard goContents={goToContents} />;
        case "StationLastBootedCard":
            return <StationLastBootedCard goContents={goToContents} />;
        case "StationThreatCard":
            return <StationThreatCard goContents={goToContents} />;
        case "StationVolStorageCard":
            return <StationVolStorageCard goContents={goToContents} />;
        case "StationLoginCard":
            return <StationLoginCard goContents={goToContents} />;
        case "NotebookBatteryHealthCard":
            return <LaptopBatteryHealthCard goContents={goToContents} />;
        case "StationWindowsElevenReadinessCard":
            return <StationWindowsElevenReadinessCard goContents={goToContents} />;
        case "ServiceDeskCard":
            return <ServiceDeskCard goContents={goToContents} />;
        case "ServiceDeskTrendsCard":
            return <ServiceDeskTrendsCard goContents={goToContents} />;
        case "ServiceDeskCreatedRequestsCard":
            return <ServiceDeskOpenedCard goContents={goToContents} />;
        case "ServiceDeskClosedRequestsCard":
            return <ServiceDeskClosedCard goContents={goToContents} />;
        case "ServerRestartCard":
            return <ServerRestartCard goContents={goToContents} />;
        case "ServiceDeskSitePerformanceCard":
            return <ServiceDeskSitePerformanceCard goContents={goToContents} />;
        case "ActivityPerUserCard":
            return <ActivityPerUser goContents={goToContents} />;
        case "ServiceDeskFeedback":
            return <ServiceDeskFeedback goContents={goToContents} />;
        case "ServiceDeskTopKeywords":
            return <ServiceDeskTopKeywords goContents={goToContents} />;
        case "ServiceDeskTopCategories":
            return <ServiceDeskTopCategories goContents={goToContents} />;
        case "SamiTokenUsage":
            return <SamiTokenUsage goContents={goToContents} />;
        case "SamiConversationCount":
            return <SamiConversationCount goContents={goToContents} />;
        case "Contents":
            return <Contents changePage={NavigateTo} />;
        default: //ToDo: Switch to error page once completed all above pages.
            return <Contents changePage={NavigateTo} />;
    }
};

export default Help;

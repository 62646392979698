import * as React from "react";
import DashCardStatus from "./cards/ServerStatus";
import DashCardServerThreat from "./cards/ServerThreatStatus";
import DashCardServerVolStorage from "./cards/ServerVolStorage";
import DashCardServerLogin from "./cards/ServerLogin";
import DashCardServerWinUpdates from "./cards/ServerWinUpdates";
import DashCardBackups from "./cards/ServerBackups";
import DashCardUnexpectedServerShutdown from "./cards/UnexpectedServerShutdown";
import DashCardCSERemoteAgent from "./cards/CSERemoteAgent";
import DashCardAppUsage from "./cards/AppUsage";
import DashCardStationsLastBooted from "./cards/StationsLastBooted";
import DashCardStationThreatStatus from "./cards/StationThreatStatus";
import DashCardStationVolStorage from "./cards/StationVolStorage";
import DashCardStationLoginInfo from "./cards/StationLoginInfo";
import DashCardServiceDeskStats from "./cards/ServiceDeskStats";
import DashCardNetworkDeviceStatus from "./cards/NetworkDeviceStatus";
import DashCardLaptopBatteryHealth from "./cards/LaptopBatteryHealth";
import DashCardStationWindowsElevenReadiness from "./cards/StationWindowsElevenReadiness";
import DashCardServiceDeskTrends from "./cards/ServiceDeskTrends";
import DashCardServerRebootRequired from "./cards/ServerRebootRequired";
import DashCardServiceDeskPerformance from "./cards/ServiceDeskPerformance";
import DashCardActivityPerUser from "./cards/ActivityPerUser";
import ErrorCard from "./cards/ErrorCard";
import DashCardNetworkSwitchDeviceStatus from "./cards/NetworkDeviceStatusSwitches/NetworkSwitchDeviceStatus";
import DashCardNetworkWAPDeviceStatus from "./cards/NetworkDeviceStatusWAP/NetworkWAPDeviceStatus";
import DashCardServerWinUpdatesInstalled from "./cards/ServerWinUpdatesInstalled/DashCardServerWinUpdatesInstalled";
import DashCardServiceDeskOpenRequests from "./cards/ServiceDeskOpenRequests/ServiceDeskOpenRequests";
import DashCardServiceDeskClosedRequests from "./cards/ServiceDeskClosedRequests/ServiceDeskClosedRequests";
import { DashCardServiceDeskFeedback } from "./cards/ServiceDeskFeedback/ServiceDeskFeedback";
import { DashCardServiceDeskTopKeywords } from "./cards/ServiceDeskTopKeywords/ServiceDeskTopKeywords";
import { DashCardSamiKnowledgeBase } from "./cards/SamiKnowledgeBaseFeedback/SamiKnowledgeBaseFeedback";
import { DashCardServiceDeskTopCategories } from "./cards/ServiceDeskTopCategories/ServiceDeskTopCategories";
import { DashCardSamiTokenUsage } from "./cards/SamiTokenUsage/SamiTokenUsage";
import { DashCardSamiConversationCount } from "./cards/SamiConversationCount/SamiConversationCount";
import { LogError } from "../analytics/eventTracker";
import { DashCardServiceDeskSLA } from "./cards/ServiceDeskSLA/ServiceDeskSLA";
import { DashCardAssetChanges } from "./cards/AssetChanges/AssetChanges";

type RenderCardProps = {
    animate?: boolean,
    cardName: string,
    cardType: number,
    custID?: string,
    startDate?: Date,
    endDate?: Date,
    raOnly?: boolean,
    customID?: string,
    cardRendered?: () => void, //Event fired when HTTPGET has completed.
    disableReRender?: boolean,
    isCSE?: boolean,
    localPreview: boolean,
    OpenHelpPopup: () => void
};
type RenderCardState = {
    hasError: boolean
};

// /**
//  * RenderCard
//  * @description Dynamically renders cards based on the dashboard local storage
//  * @param {String} cardName String containing which Card item to render e.g "ServerStatus"
//  * @param {Number} cardType Int defining which individual card UI to render e.g cardName: "ServerStatus", cardType: 1 === return CardGauge
//  * @param {String} custID Customer ID string
//  * @param {Date} startDate Start query date
//  * @param {Date} endDate End query date
//  * @param {boolean} localPreview Boolean to disable API get and popups
//  * @returns React component for requested card / card type
//  */
// const RenderCard: React.FC<RenderCardProps> = ({ cardName, cardType, custID, startDate, endDate, localPreview, OpenHelpPopup }) => {
//     return (
//         <>
//             <GetCard
//                 cardName={cardName}
//                 cardType={cardType}
//                 custID={custID}
//                 startDate={startDate}
//                 endDate={endDate}
//                 localPreview={localPreview}
//                 OpenHelpPopup={OpenHelpPopup}
//             />
//         </>
//     );
// };

class RenderCard extends React.PureComponent<RenderCardProps, RenderCardState> {
    state: RenderCardState = {
        hasError: false
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            LogError("Card Render Error", {
                cardName: this.props.cardName,
                cardType: this.props.cardType,
                custID: this.props.custID,
                customID: this.props.customID,
                animate: this.props.animate,
                disableReRender: this.props.disableReRender,
                isCSE: this.props.isCSE,
                cardRendered: this.props.cardRendered,
                localPreview: this.props.localPreview
            });
            return <ErrorCard cardName={this.props.cardName} openHelpPopup={this.props.OpenHelpPopup} />;
        }

        return (
            <GetCard
                cardName={this.props.cardName}
                cardType={this.props.cardType}
                custID={this.props.custID}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                raOnly={this.props.raOnly}
                customID={this.props.customID}
                animate={this.props.animate}
                disableReRender={this.props.disableReRender}
                isCSE={this.props.isCSE}
                cardRendered={this.props.cardRendered}
                localPreview={this.props.localPreview}
                OpenHelpPopup={this.props.OpenHelpPopup}
            />
        );
    }
}

const GetCard: React.FC<RenderCardProps> = ({
    animate,
    cardName,
    cardType,
    custID,
    startDate,
    endDate,
    raOnly,
    customID,
    disableReRender,
    isCSE,
    cardRendered,
    localPreview,
    OpenHelpPopup
}) => {
    switch (cardName) {
        case "ServerStatus":
            return (
                <DashCardStatus
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerThreat":
            return (
                <DashCardServerThreat
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerVolStorage":
            return (
                <DashCardServerVolStorage
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerLogin":
            return (
                <DashCardServerLogin
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerWinUpdates":
            return (
                <DashCardServerWinUpdates
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerWinUpdatesInstalled":
            return (
                <DashCardServerWinUpdatesInstalled
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "Backups":
            return (
                <DashCardBackups
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "UnexpectedServerShutdown":
            return (
                <DashCardUnexpectedServerShutdown
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "CSERemoteAgent":
            return (
                <DashCardCSERemoteAgent
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "AppUsage":
            return (
                <DashCardAppUsage
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "StationsLastBooted":
            return (
                <DashCardStationsLastBooted
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "StationThreatStatus":
            return (
                <DashCardStationThreatStatus
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "StationVolStorage":
            return (
                <DashCardStationVolStorage
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "StationLoginInfo":
            return (
                <DashCardStationLoginInfo
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskStats":
            return (
                <DashCardServiceDeskStats
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "NetworkDeviceStatus":
            return (
                <DashCardNetworkDeviceStatus
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "NetworkSwitchDeviceStatus":
            return (
                <DashCardNetworkSwitchDeviceStatus
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "NetworkWAPDeviceStatus":
            return (
                <DashCardNetworkWAPDeviceStatus
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "LaptopBatteryHealth":
            return (
                <DashCardLaptopBatteryHealth
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "StationWindowsElevenReadiness":
            return (
                <DashCardStationWindowsElevenReadiness
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskTrends":
            return (
                <DashCardServiceDeskTrends
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServerRebootRequired":
            return (
                <DashCardServerRebootRequired
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskPerformance":
            return (
                <DashCardServiceDeskPerformance
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ActivityPerUser":
            return (
                <DashCardActivityPerUser
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate}
                    endDate={endDate}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskOpenRequests":
            return (
                <DashCardServiceDeskOpenRequests
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskClosedRequests":
            return (
                <DashCardServiceDeskClosedRequests
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskFeedback":
            return (
                <DashCardServiceDeskFeedback
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskTopKeywords":
            return (
                <DashCardServiceDeskTopKeywords
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskTopCategories":
            return (
                <DashCardServiceDeskTopCategories
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "SamiKBFeedback":
            return (
                <DashCardSamiKnowledgeBase
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "SamiTokenUsage":
            return (
                <DashCardSamiTokenUsage
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "SamiConversationCount":
            return (
                <DashCardSamiConversationCount
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "ServiceDeskSLA":
            return (
                <DashCardServiceDeskSLA
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        case "AssetChanges":
            return (
                <DashCardAssetChanges
                    animate={animate}
                    cardID={cardName}
                    cardType={cardType}
                    custID={custID}
                    startDate={startDate!}
                    endDate={endDate!}
                    raOnly={raOnly}
                    customID={customID}
                    disableReRender={disableReRender}
                    isCSE={isCSE}
                    cardRendered={cardRendered}
                    openHelpPopup={OpenHelpPopup}
                    localPreview={localPreview}
                />
            );
        default:
            return <></>;
    }
};

export default RenderCard;

import { tr } from "../../helpers/languages";

// export type CardNames =
//     | "ServerStatus"
//     | "ServerThreat"
//     | "ServerVolStorage"
//     | "ServerLogin"
//     | "ServerWinUpdates"
//     | "ServerWinUpdatesInstalled"
//     | "Backups"
//     | "UnexpectedServerShutdown"
//     | "CSERemoteAgent"
//     | "AppUsage"
//     | "StationsLastBooted"
//     | "StationThreatStatus"
//     | "StationVolStorage"
//     | "StationLoginInfo"
//     | "ServiceDeskStats"
//     | "NetworkDeviceStatus"
//     | "NetworkSwitchDeviceStatus"
//     | "NetworkWAPDeviceStatus"
//     | "LaptopBatteryHealth"
//     | "StationWindowsElevenReadiness"
//     | "ServiceDeskTrends"
//     | "ServiceDeskOpenRequests"
//     | "ServiceDeskClosedRequests"
//     | "ServerRebootRequired"
//     | "ServiceDeskPerformance"
//     | "ActivityPerUser"
//     | "ServiceDeskFeedback";

export type Card = {
    cardItem: string,
    name: string,
    availableUI: string[],
    versionAdded: string
};

export const Cards: Card[] = [
    {
        cardItem: "ServerStatus",
        name: tr("DASHBOARD_SERSTATUS_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServerThreat",
        name: tr("DASHBOARD_AV_SERVER_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServerVolStorage",
        name: tr("DASHBOARD_VOL_STOR_SERVER_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServerLogin",
        name: tr("DASHBOARD_LOGIN_SERVER_TITLE"),
        availableUI: ["Bar Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServerWinUpdates",
        name: tr("DASHBOARD_WIN_UPDATE_TITLE"),
        availableUI: ["Classic Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServerWinUpdatesInstalled",
        name: tr("DASHBOARD_WIN_UPDATE_INSTALLED_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2023.1.0.5"
    },
    {
        cardItem: "Backups",
        name: tr("DASHBOARD_BACKUPS_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "UnexpectedServerShutdown",
        name: tr("DASHBOARD_UNEXP_SHUTDOWN_TITLE"),
        availableUI: ["Bar Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "CSERemoteAgent",
        name: tr("DASHBOARD_CSE_REMAGENT_TITLE"),
        availableUI: ["Classic Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "AppUsage",
        name: tr("DASHBOARD_APP_USAGE_TITLE"),
        availableUI: ["CardBarGraph"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "StationsLastBooted",
        name: tr("DASHBOARD_STATIONLASTSEEN_TITLE"),
        availableUI: ["Bar Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "StationThreatStatus",
        name: tr("DASHBOARD_AV_STATION_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "StationVolStorage",
        name: tr("DASHBOARD_VOL_STOR_STATION_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "StationLoginInfo",
        name: tr("DASHBOARD_LOGIN_STATION_TITLE"),
        availableUI: ["Bar Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServiceDeskStats",
        name: tr("DASHBOARD_SERVICEDESK_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "NetworkDeviceStatus",
        name: tr("DASHBOARD_NETWORK_DEVICE_STATUS_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "NetworkSwitchDeviceStatus",
        name: tr("DASHBOARD_NETWORK_DEVICE_STATUS_SWITCH_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2023.1.0.3"
    },
    {
        cardItem: "NetworkWAPDeviceStatus",
        name: tr("DASHBOARD_NETWORK_DEVICE_STATUS_WAP_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2023.1.0.3"
    },
    {
        cardItem: "LaptopBatteryHealth",
        name: tr("DASHBOARD_LAPTOP_BATTERY_HEALTH_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "StationWindowsElevenReadiness",
        name: tr("DASHBOARD_WINDOWS_ELEVEN_READY_TITLE"),
        availableUI: ["Classic Card", "Pie Chart Card", "Gauge Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServiceDeskTrends",
        name: tr("DASHBOARD_SERVICEDESK_TRENDS_TITLE"),
        availableUI: ["Line Chart Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServiceDeskOpenRequests",
        name: tr("DASHBOARD_SERVICEDESK_OPEN_REQ_TITLE"),
        availableUI: ["Line Chart Card"],
        versionAdded: "2023.1.0.6"
    },
    {
        cardItem: "ServiceDeskClosedRequests",
        name: tr("DASHBOARD_SERVICEDESK_CLOSED_REQ_TITLE"),
        availableUI: ["Line Chart Card"],
        versionAdded: "2023.1.0.6"
    },
    {
        cardItem: "ServerRebootRequired",
        name: tr("DASHBOARD_SERVER_RESTART_TITLE"),
        availableUI: ["Bold Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ServiceDeskPerformance",
        name: tr("DASHBOARD_SERVICEDESK_PERF_TITLE"),
        availableUI: ["Performance Card"],
        versionAdded: "2022.1.0.10"
    },
    {
        cardItem: "ActivityPerUser",
        name: tr("DASHBOARD_USER_ACTIVITY_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2022.1.0.12"
    },
    {
        cardItem: "ServiceDeskFeedback",
        name: tr("DASHBOARD_SERVICEDESK_FEEDBACK_TITLE"),
        availableUI: ["Classic Card", "Gauge Chart Card"],
        versionAdded: "2024.1.0.1"
    },
    {
        cardItem: "ServiceDeskTopKeywords",
        name: tr("DASHBOARD_SERVICEDESK_KEYWORDS_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2024.1.0.3"
    },
    {
        cardItem: "ServiceDeskTopCategories",
        name: tr("DASHBOARD_SERVICEDESK_CATEGORIES_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2024.1.0.3"
    },
    // {
    //     cardItem: "SamiKnowledgeBase",
    //     name: tr("DASHBOARD_SAMI_KB_TITLE"),
    //     availableUI: ["Classic Card"],
    //     versionAdded: "2024.1.0.3"
    // },
    {
        cardItem: "SamiTokenUsage",
        name: tr("DASHBOARD_SAMI_TOKEN_USAGE_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2024.1.0.4"
    },
    {
        cardItem: "SamiConversationCount",
        name: tr("DASHBOARD_SAMI_CONVERSATION_COUNT_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2024.1.0.4"
    },
    {
        cardItem: "AssetChanges",
        name: tr("DASHBOARD_ASSET_CHANGES_TITLE"),
        availableUI: ["Classic Card"],
        versionAdded: "2024.1.0.8"
    },
];
